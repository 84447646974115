import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { standardWrapper } from "../styles/helpers"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundStyled className="wrapper">
      <div className="notTitle">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </NotFoundStyled>
  </Layout>
)

const NotFoundStyled = styled.div`
  ${standardWrapper};

  .notTitle {
    padding: 10rem 0;
    text-align: center;
  }
`

export default NotFoundPage
